@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #252526;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer utilities {
  .border-gradient {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(180deg, rgba(0, 255, 240, 0.9) -8.13%, rgba(255, 255, 255, 0) 92.62%) 1;
    border-radius: 4px;
  }

  .border-gradient-box {
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(152.86deg, #08B098 0.6%, #000115 14.49%, #08B098 59.79%, rgba(44, 46, 49, 0.264174) 82.67%, #000115 93.99%) 1;
    border-radius: 4px;
  }
}
